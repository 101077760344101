// @flow

import chihuahua from './assets/chihuahua.svg';
import chihuahuaInPhone from './assets/chihuahua-in-phone.svg';
import dateAndTimeBlue from './assets/date-and-time-blue.svg';
import dateAndTimeGreen from './assets/date-and-time-green.svg';
import dateAndTimeOrange from './assets/date-and-time-orange.svg';
import dog from './assets/dog.svg';
import dogInPhoneBlue from './assets/dog-in-phone-blue.svg';
import dogInPhoneBlueSitting from './assets/dog-in-phone-blue-sitting.svg';
import goldenRetrieverInPhone from './assets/golden-retriever-in-phone.svg';
import goldenRetrieverWithSun from './assets/golden-retriever-with-sun.svg';
import house from './assets/house.svg';
import houseBlue from './assets/house-blue.svg';
import houseBlueSitting from './assets/house-blue-sitting.svg';
import houseBoardingBlue from './assets/house-boarding-blue.svg';
import husky from './assets/husky.svg';
import huskyInPhone from './assets/husky-in-phone.svg';
import insured from './assets/insured.svg';
import one from './assets/one.svg';
import support from './assets/support.svg';
import two from './assets/two.svg';
import three from './assets/three.svg';
import vetted from './assets/vetted.svg';
export default {
  chihuahua,
  chihuahuaInPhone,
  dateAndTimeBlue,
  dateAndTimeGreen,
  dateAndTimeOrange,
  dog,
  dogInPhoneBlue,
  dogInPhoneBlueSitting,
  goldenRetrieverInPhone,
  goldenRetrieverWithSun,
  house,
  houseBlue,
  houseBlueSitting,
  houseBoardingBlue,
  husky,
  huskyInPhone,
  insured,
  one,
  support,
  two,
  three,
  vetted
};